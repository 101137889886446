import { AcademicCapIcon, CubeIcon } from "@heroicons/react/24/solid"
import { useTooltip } from "../hooks/useTooltip"

/**
 * URL to the overpass documentation.
 */
const docsUrl = "https://dev.azure.com/GWS-gevis/Microsoft%20and%20Azure%20Products/_git/cloud-overpass"

/***
 * Default App Header.
 */
export const Header = () => {
  const { addTooltip } = useTooltip()
  addTooltip({
    id: "t-help",
    label: "help and more"
  })
  return (
    <div className="flex font-semibold mb-2 h-14 border-b items-center">
      <h1 className="flex grow text-xl cursor-pointer">
        <CubeIcon className='w-6 h-6 mr-4 text-emerald-600 items-center mt-1' />
        Overpass
      </h1>
      <div id="t-help" className="flex items-center bg-gray-200 rounded-md p-0.5 hover:bg-gray-300 cursor-pointer text-sm">
        <AcademicCapIcon className="h-4 w-4 text-sm text-gray-600 mr-1" />
        <a href={docsUrl} target="_blank">docs</a>
      </div>
    </div>
  )
}