import { useRef, useState } from "react";
import { ItemFile } from "../core/item";
import classNames from "classnames";
import { buttonStyle } from "../assets/theme";
import { useNotification } from "../hooks/useNotifcation";

type OnUpload = (item: ItemFile, onProgress?: (size: number, loaded: number) => void) => () => void;

type UplaodItemFile = { name: string, file: File | undefined, data: {} }

export const ItemUploader = ({ onUpload }: { onUpload: OnUpload }) => {
  const [itemFile, setItemFile] = useState<UplaodItemFile>({ name: "", file: undefined, data: {} })
  const [uploadState, setUploadState] = useState<UploadState>({ visible: false, size: -1, loaded: 0 });
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const notification = useNotification();

  const cancelUpload = useRef(() => { });

  const isDisabled = () => !(itemFile.name && itemFile.data)

  const onFileChange = (event: any) => {
    setItemFile({ name: event.target.files[0].name, file: event.target.files[0], data: {} })
  };

  const onProgress = (s: number, e: number) => {
    if (e === s) {
      setUploadState({ visible: true, loaded: e, size: s })
      notification.setVisible("File upload finished.")
    } else {
      setUploadState({ visible: true, loaded: e, size: s })
    }
  }

  const onFileUpload = () => {
    if (itemFile.file) {
      setUploadState({ visible: true, loaded: 0, size: itemFile.file.size })

      cancelUpload.current = onUpload({ 
        file: itemFile.file, 
        name: itemFile.name, 
        data: itemFile.data }, 
      onProgress);
    }
  }

  const onCancelUpload = () => {
    cancelUpload.current();
    setUploadState({ visible: false, loaded: 0, size: 0 })
    notification.setVisible("Upload cancelled")
  }

  const onUploadClose = () => {
    setUploadState({ visible: false, loaded: 0, size: 0 })
  }

  return <>
    <div className=' mt-4 border-b pb-4' >
      <h2 className='font-semibold'> Upload </h2>

<div className="relative">

      <UploadOverlay uploadState={uploadState} visible={false} onCancel={onCancelUpload} onUploadClose={onUploadClose} />

      <div className='mt-3'>
        <div className='flex'>
          <div className='border border-gray-200 w-full rounded-md p-2 bg-gray-50 focus:outline-none cursor-pointer text-gray-700 hover:bg-gray-100 overflow-hidden'
            onClick={() => hiddenFileInput.current!.click()}>
            {itemFile?.file ? itemFile?.file?.name : "select a file..."}
          </div>
          <input type="file" onChange={onFileChange} ref={hiddenFileInput} className="hidden" readOnly size={0} />
          <button onClick={() => hiddenFileInput.current!.click()} className={buttonStyle}>open</button>
        </div>
        <label className='text-xs text-gray-500 flex mt-1'> File </label>
      </div>

      <div className='mt-3'>
        <input
          placeholder='name'
          value={itemFile.name}
          className='border w-full rounded-md p-2 bg-gray-50'
          onChange={(e) => setItemFile({ ...itemFile, name: e.target.value })}
        />
        <div className='text-xs text-gray-500 flex mt-1'>Name</div>
      </div>

      <div className='mt-3'>
        <input
          placeholder='owner'
          className='border w-full rounded-md p-2 bg-gray-50'
          onChange={(e) => setItemFile({ ...itemFile, data: { ...itemFile.data, owner: e.target.value } })}
        />
        <div className='text-xs text-gray-500 flex mt-1'>Owner</div>
      </div>

      <div className='flex justify-end'>
        <button onClick={onFileUpload} className={classNames(buttonStyle, "text-sm", {
          "bg-gray-400": isDisabled()
        })} disabled={isDisabled()}> upload</button>
      </div>
      
    </div>
    </div>

  </>
}

type UploadState = { visible: boolean, size: number, loaded: number }
type UploadOverlayProps = { uploadState: UploadState, visible: boolean, onCancel: () => void, onUploadClose: () => void }
const UploadOverlay = ({ uploadState, onCancel, onUploadClose }: UploadOverlayProps) => {
  const { visible, loaded, size } = uploadState;

  const loadingBarWidth = size === 0 ? 380 : 380 * (loaded / size) ;
  const percentage = size === 0 ? 100 : 100 / size * loaded
  return (
    <div className={classNames('hidden absolute h-full w-full bg-gray-100  justify-center items-center ',
      {
        '!flex': visible
      }
    )}>
      <div className='w-[380px] h-2 bg-gray-500  text-xs text-center rounded-xl'>
        <div className='bg-gray-500 m-auto rounded-md text-white '>
          <div className=' w-0 bg-emerald-500 rounded-md' style={{width: loadingBarWidth}}>
            {uploadState ? (percentage.toFixed(2) + "%") : 0}
          </div>
        </div>
        <button onClick={percentage !== 100 ? onCancel : onUploadClose} className="p-1 rounded-md bg-emerald-500 text-white mt-2 hover:bg-emerald-700">
          {percentage !== 100 ? "Cancel" : "Close"}
        </button>
      </div>
    </div>
  )
}

