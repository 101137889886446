import { AnonymousCredential, ContainerClient } from "@azure/storage-blob"
import { useRef, useState } from "react"
import { Item, ItemFile } from "../core/item"

interface AppConfiguration {
  containerUrl?: string
  containerClient?: ContainerClient
}

export const useAppItems = (sasLink?: string | null) => {
  const [items, setItems] = useState<Item[]>()
  const options = useRef<AppConfiguration>({})

  const fetchItems = () => {
    if (!options.current.containerClient)
      return;

    listItemBlobs(options.current.containerClient).then(items => {
      const sortItemsByDate = (a: Item, b: Item) => Number(b.createdOn) - Number(a.createdOn);
      setItems(items.sort(sortItemsByDate));
    })
  }

  const setContainerLink = (link: string) => {
    try {
      options.current = ({
        ...options,
        containerUrl: link,
        containerClient: new ContainerClient(link, new AnonymousCredential(), {})
      })
      fetchItems()
    } catch {
      alert("Not an valid uri")
    }
  }

  const removeItem = (name: string) => {
    if (!options.current.containerClient) return;
    removeBlob(options.current.containerClient, name).then(() => fetchItems())
  }

  const addItem = (itemFile: ItemFile, onProgress?: (size: number, e: number) => void): CancelUpload => {
    const controller = new AbortController();
    const setCancel: CancelUpload = () => {
      controller.abort("abort")
    }

    if (options.current.containerClient)
      uploadBlobFromLocalPath(options.current.containerClient, itemFile, controller, onProgress).then(() => fetchItems())

    return setCancel;
  }

  if (sasLink && sasLink !== options.current.containerUrl) {
    setContainerLink(sasLink)
  }

  return { items, setContainerLink, removeItem, addItem }
}

type CancelUpload = () => void;

//#region blob operations
async function uploadBlobFromLocalPath(containerClient: ContainerClient, item: ItemFile, controller: AbortController, onProgress?: (size: number, loaded: number) => void) {
  const blockBlobClient = containerClient.getBlockBlobClient(item.name);
  try {
    await blockBlobClient.uploadData(await item.file.arrayBuffer(), {
      onProgress: (e) => onProgress ? onProgress(item.file.size, e.loadedBytes) : {},
      abortSignal: controller.signal,
      metadata: item.data
    })
  }
  catch {

  }
}

async function removeBlob(containerClient: ContainerClient, blobName: string) {
  await containerClient.deleteBlob(blobName, {})
}

/**
 * Method to list all items in storage container.
 * @param containerClient azure container client.
 * @returns list of items.
 */
async function listItemBlobs(containerClient: ContainerClient): Promise<Item[]> {

  let iterator = containerClient.listBlobsFlat().byPage();
  let response = (await iterator.next()).value;

  const itemList: Item[] = [];
  const ignoreIndexFilter = (blob: any) => blob.name !== "index.html";
  let i = 0;

  for (const blob of response.segment.blobItems.filter(ignoreIndexFilter)) {
    const blobClient = containerClient.getBlobClient(blob.name);
    itemList.push({
      index: ++i,
      name: blob.name,
      url: blobClient.url,
      createdOn: blob.properties.createdOn
    })
  }

  return itemList;
}
//#endregion
