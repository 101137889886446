import { CSSProperties, createContext, useContext, useEffect, useRef, useState } from "react";
/**
 * Tooltip Provider
 * Simple Tooltip Provider
 */

interface Tooltip {
  id: string,
  label: string,
  position?: "top" | "right" | "bottom" | "left"
  style?: TooltipStyle
}

interface TooltipStyle extends CSSProperties {
  display?: "none" | "block" | undefined,
  top?: number | undefined,
  left?: number | undefined,
  right?: number | undefined,
  bottom?: number | undefined,
  transform?: string | undefined
}

interface TooltipActions {
  addTooltip: (tooltip: Tooltip | Tooltip[]) => void
  removeTooltip: (id: string) => void
}

const TooltipContext = createContext<TooltipActions>({
  addTooltip: () => { },
  removeTooltip: () => { }
});

export const TooltipProvider = ({ children }: { children: React.ReactNode }) => {
  const [tooltip, setTooltip] = useState<Tooltip | undefined>(undefined)
  const tooltipList = useRef<Tooltip[]>([])
  const tooltipWrapper = useRef<HTMLDivElement>(null)

  const addTooltip = (tooltips: Tooltip | Tooltip[]) => {
    if (!Array.isArray(tooltips))
      tooltips = [tooltips]

    tooltips.forEach(tooltip => {
      if (tooltipList.current.find(t => t.id === tooltip.id))
        return
      tooltipList.current.push(tooltip)
    })
  }

  const removeTooltip = (id: string) => {
    tooltipList.current = tooltipList.current.filter(t => t.id !== id).map(t => t);
  }

  const tooltipActions: TooltipActions = { addTooltip, removeTooltip }

  useEffect(() => {
    const wrapperRect = tooltipWrapper.current?.getBoundingClientRect();

    tooltipList.current.map(tooltip => {
      const element = document.getElementById(tooltip.id);

      if (!element) {
        return
      }

      const onMouseEnter = () => {
        const elementRect = element.getBoundingClientRect()
        const position = calcTooltipPosition(wrapperRect!, elementRect)

        setTooltip({
          ...tooltip,
          style: {
            display: "block",
            left: position.left,
            top: position.top,
            bottom: position.bottom,
            right: position.right,
            textOverflow: "ellipsis",
            width: 0,
          }
        })
      }

      const onMouseLeave = () => {
        setTooltip(undefined)
      }

      element.addEventListener("mouseenter", onMouseEnter);
      element.addEventListener("mouseleave", onMouseLeave);

      return () => {
        element.removeEventListener("mouseenter", onMouseEnter);
        element.removeEventListener("mouseleave", onMouseLeave);
      };
    })
  })

  return (
    <TooltipContext.Provider value={tooltipActions}>
      <div ref={tooltipWrapper}>
        {
          tooltip ? <div style={tooltip.style} className={"absolute "}>
            <div style={{ transform: "translate(-50%, 0)" }} className={"absolute bg-gray-800 whitespace-nowrap text-white text-sm z-50 rounded-md px-1"}>
              {tooltip.label}
            </div>
          </div> : <></>
        }
        {children}
      </div>
    </TooltipContext.Provider>
  )
}

export const useTooltip = () => useContext(TooltipContext)

const calcTooltipPosition = (wrapper: DOMRect, element: DOMRect) => {
  const maxWidth = 100
  const relativeX = element.left - wrapper.left
  const relativeY = element.top - wrapper.top
  const wrapperMaxX = wrapper.width


  const position: {
    top: undefined | number,
    right: undefined | number,
    bottom: undefined | number,
    left: undefined | number,
  } = {
    top: relativeY - element.height + 4,
    right: undefined,
    bottom: undefined,
    left: relativeX + (element.width / 2)
  }

  if (relativeX + (maxWidth / 2) >= wrapperMaxX) {
    position.left = relativeX - (element.width / 2)
  }

  if(relativeY + element.height + 20 <= wrapper.height) {
    position.top = relativeY + element.height + 4
  }

  return position
}