import { useAppItems } from './hooks/useItems';
import { ItemTable } from './components/ItemTable';
import { ItemUploader } from './components/ItemUpload';
import { Header } from './components/Header';
import { ItemContainer } from './components/ItemContainer';
import { createContext } from 'react';
import { NotificationProvider } from './hooks/useNotifcation';
import { TooltipProvider } from './hooks/useTooltip';

export const ThemeContext = createContext('light');

/**
 * Entry point to the app.
 */
function App() {
  const url = document.URL.match(/\?container=(?<url>.*$)/)?.groups!.url
  const { items, setContainerLink, addItem, removeItem } = useAppItems(url);
  const notificationStyle = 'w-full bg-gray-800 h-14 text-white absolute z-50 flex items-center px-2'

  return (
    <div className='px-4 h-full'>
      <section className='max-w-xl m-auto relative'>
        <TooltipProvider>
          <NotificationProvider className={notificationStyle} >
            <Header />
            <ItemContainer url={url} onContainerSet={setContainerLink} />
            <ItemUploader onUpload={addItem} />
            <ItemTable items={items} onRemoveItem={removeItem} />
          </NotificationProvider>
        </TooltipProvider>
      </section>
    </div>
  );
}

export default App;