import { createContext, useContext, useState } from "react";
import { Transition } from '@headlessui/react'
import { XMarkIcon } from "@heroicons/react/24/solid";

interface Notification {
  message: string
  visible?: boolean
  type?: "info" | "warning" | "error"
}

interface NotificationHandle {
  setNotification?: (notification: Notification, duration: number) => void
}

const NotificationContext = createContext<NotificationHandle>({});

export const NotificationProvider = ({ className, children }: { className?: string, children: any }) => {
  const [notification, setNotification] = useState<Notification>()

  const notificationHandle = (notification: Notification, duration: number) => {
    setNotification({ ...notification, visible: true })
    setTimeout(() => {
      setNotification({ message: "", visible: false, type: "info" })
    }, duration)
  }

  return <>
    <NotificationContext.Provider value={{ setNotification: notificationHandle }}>
      <Transition
        show={notification?.visible ? notification?.visible : false}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={className}>
          <XMarkIcon className="h-5 w-5 text-white mr-2 mt-0.5 cursor-pointer" onClick={() => setNotification({ message: "", visible: false })} />
          {notification?.message}
        </div>
      </Transition>
      {children}
    </NotificationContext.Provider>
  </>
}

/**
 * Hook is used to show a notification.
 * @returns 
 */
export const useNotification = () => {
  const notContext = useContext(NotificationContext);

  const showNotification = (message: string, duration: number = 1000, type: "info" | "warning" | "error" = "info") => {
    if (notContext.setNotification)
      notContext.setNotification({
        message: message,
        type: type
      }, duration)
  }

  return {
    setVisible: showNotification
  }
}

