import { useState } from "react";
import { Item } from "../core/item";
import { ChevronDownIcon, ChevronUpIcon, ClipboardDocumentIcon, ClipboardIcon, FolderArrowDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { buttonStyle } from "../assets/theme";
import classNames from "classnames";
import { useNotification } from "../hooks/useNotifcation";
import { useTooltip } from "../hooks/useTooltip";

/**
 * Item table properties.
 */
interface ItemTableProps {
  items: Item[] | undefined
  onRemoveItem?: (name: string) => void
}

/**
 * Renders the items as item table for the app.
 * @param props Item table properties.
 * @returns Items as table element.
 */
export function ItemTable(props: ItemTableProps) {
  const notification = useNotification();
  const { addTooltip, removeTooltip } = useTooltip();

  const [dateDesc, setDateDesc] = useState(true);
  const [itemToRemove, setRemoveState] = useState({ itemName: "", visible: false })
  const { items = [], onRemoveItem } = props;

  // styles and formats
  const colStyle = "text-left text-sm text-gray-900 py-2 px-1"
  const createdOnFormat = {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    dayPeriod: "long",
    hour: "numeric",
    hourCycle: "h24",
    minute: "numeric",
    second: "numeric",
  }

  const onSortByDate = () => {
    if (dateDesc) {
      setDateDesc(false);
      items.sort((a: Item, b: Item) => Number(a.createdOn) - Number(b.createdOn))
    } else {
      setDateDesc(true);
      items.sort((a: Item, b: Item) => Number(b.createdOn) - Number(a.createdOn))
    }
  }

  const onRemove = (itemName: string) => {
    setRemoveState({ itemName: itemName, visible: true })
  }

  const removeItem = (itemName: string) => {
    if (onRemoveItem)
      onRemoveItem(itemName)

    setRemoveState({ itemName: "", visible: false })
    notification.setVisible(`Item ${itemName} removed.`)
  }

  const onCopy = (itemUri: string) => {
    navigator.clipboard.writeText(itemUri)
    notification.setVisible("Url set to clipboard.")
  }

  return (
    <section className="mt-4 relative">
      <h1 className="font-semibold">Items</h1>

      <ItemRemoveDialog name={itemToRemove.itemName} visible={itemToRemove.visible} onConfirm={() => { removeItem(itemToRemove.itemName); }} onCancel={() => setRemoveState({ itemName: "", visible: false })} />
      <div className="overflow-x-auto overflow-y-hidden w-full relative  scrollbar-thumb-slate-400  scrollbar scrollbar-h-1.5 scrollbar-thumb-rounded-xl">

        <table className="divide-y divide-gray-300  w-full">
          <thead>
            <tr>
              <th scope="col" className={colStyle + " "} style={{ width: 30 }}> #</th>
              <th scope="col" className={colStyle + " font-semibold"}>
                Name
              </th>
              <th scope="col" className={colStyle + " cursor-pointer"} onClick={onSortByDate} >
                <span className='flex items-center'>
                  {
                    dateDesc ?
                      <ChevronDownIcon className='h-5 w-5 mr-2' /> :
                      <ChevronUpIcon className='h-5 w-5 mr-2' />
                  }
                  Created
                </span>
              </th>
              <th scope="col" className={classNames(colStyle, "text-right")}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 w-full">
            {items.map((item: any) => {
              addTooltip!([
              {
                id: "t-copy-" + item.index, 
                label: "Set to clipboard"
              },
              {
                id: "t-remove-" + item.index, 
                label: "Remove item"
              },
              {
                id: "t-download-" + item.index, 
                label: "Download item"
              },
            
            ])
            return (
              <tr key={item.index} className='hover:bg-slate-100 cursor-pointer'>
                <td className={colStyle + " font-semibold"}>
                  {item.index}
                </td>
                <td className={colStyle + " font-semibold "}>
                  {item.name}
                </td>
                <td className={colStyle + " text-nowrap"}>
                  {item.createdOn.toLocaleString('de-DE', createdOnFormat)}
                </td>
                <td className={colStyle + " sticky right-0 z-10 float-right"}>
                  <div className='flex justify-end w-min border-l bg-white shrink '>
                    <a href={item.url} className={buttonStyle} onClick={() => { notification.setVisible("Item is downloaded.") }} id={"t-download-" + item.index}> 
                      <FolderArrowDownIcon className="h-5 w-5" />
                    </a>
                    <button className={buttonStyle} onClick={() => onCopy(item.url)} id={"t-copy-" + item.index}> 
                      <ClipboardIcon className="h-5 w-5" />
                    </button>
                    <button onClick={() => onRemove(item.name)} className={buttonStyle} id={"t-remove-" + item.index}>
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            )})}
          </tbody>
        </table>
      </div>
    </section>
  )
}


const ItemRemoveDialog = ({ visible, name, onConfirm, onCancel }: { visible: boolean, name: string, onConfirm: () => void, onCancel: () => void }) => {
  return (
    <div className={classNames("absolute h-full w-full bg-gray-50/100 flex justify-center items-center z-20", { hidden: !visible })}>
      <div className="flex-col text-center ">
        <h1 className="mb-4 font-semibold">Remove Item {name}?</h1>
        <div className="flex items-center justify-center">
          <button onClick={onConfirm} className={buttonStyle}>
            Confirm
          </button>
          <button onClick={onCancel} className={buttonStyle}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}