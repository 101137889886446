import { useState } from "react"
import { buttonStyle } from "../assets/theme"
import { useNotification } from "../hooks/useNotifcation";

/**
 * Container element to set the url to the storage container. 
 */
export const ItemContainer = ({ url, onContainerSet }: { url?: string, onContainerSet?: (sasToken: string) => void }) => {
  const notification = useNotification();
  const [inputValue, setState] = useState<string | undefined>(url)

  const onSave = () => {
    if (onContainerSet && inputValue) {
      onContainerSet(inputValue)
      notification.setVisible("Container SAS saved")
    }
  }

  return <>
    <div className='border-b pb-4'>
      <h2 className='font-semibold py-2'>Container</h2>
      <div className="flex">
        <input
          placeholder='SAS Link'
          className='border w-full rounded-md p-2 bg-gray-50'
          value={inputValue}
          onChange={(e) => setState(e.target.value)}
        />
        <button onClick={onSave} className={buttonStyle} >
          save
        </button>
      </div>
      <div className="text-sm grow text-gray-500 mt-1"> SAS Container URL </div>
    </div>
  </>
}

